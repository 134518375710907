import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'


class PrivacyPage extends Component {
  render() {  

return (
  <Layout page={"home"}>
  <div className="main">
  <div class="home-main">
    
      <h1 className="title">Gebruiksvoorwaarden</h1>
	  <p className="text">
	  Deze pagina beschrijft de gebruiksvoorwaarden van de diensten verlend via deze website.
	  </p>

	  <h3 className="text">AARD VAN DE DIENSTEN</h3>
	
	  <p className="text">
	  Via steunverkoop kunnen personen organisaties steunen en mee helpen hun doel te bereiken. U doet dit door producten te kopen. De producten worden zorgvoldug selecteerd en te koop aangeboden via deze webshop en eventuele andere kanalen.
	  </p>
	  
	  <h3 className="text">TERUGKEERBELEID</h3>
	 
	 <p className="text">	
	 Indien u van oordeel bent dat de diensten niet voldoen aan uw verwachtingen, neem dan contact op via onderstaande coördinaten. Gelieve duidelijk te beschrijven welke uw klachten zijn zodat we kunnen komen tot een passende oplossing.
     </p>
	

<h3 className="text">
CONTACTEER ONS
</h3>

<p className="text">
CHATEAU BoHo, Schoenstraat 46-48, 2140 BOrgerHOut-Antwerpen.
</p> 

<p className="text">
  Ondernemingsnummer : 0858304005
</p> 

<p className="text">
  Email : Info@ChateauBoHo.be
</p> 

<p className="text">
  Telefoon : Joris +32 474 99 69 59 / Thomas +32 496 95 64 46 / Manu +32 478 61 95 76
</p> 

  </div>
  </div>
  
  </Layout>

)
  }
}
export default PrivacyPage

